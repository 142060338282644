import React from "react";
import "./Footer.css";
import zainshop from "./zainshop.PNG";
import instagram from "./instagram.svg";
import tel from "./telephoneblack.png";
import whatsapp from "./whatsappblack.png";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="logo">
        {/* ضع شعار الزبون هنا */}
        <img src={zainshop} alt="Logo" />
      </div>
      <section>
        <h2>اتصل بنا</h2>
        <details>
          <summary>الاتصال</summary>
          <a href="tel:0528919198" target="_blank">
            الاتصال
          </a>
          <br />
          <a href="https://wa.me/972528919198" target="_blank">
            واتساب
          </a>
        </details>
        <details>
          <summary>الأسئلة المتكررة</summary>
          <h3>هل التوصيل مجاني ؟</h3>
          <p>نعم، التوصيل مجاني حتى باب منزلك</p>
          <h3>ما هي الهدية اللتي احصل عليها عند شراء المنفاخ ؟</h3>
          <p>عند شراء المنفاخ تحصل على علبة لاصلاح الثقوب في العجل بقيمة 50₪</p>
        </details>
      </section>
      <section>
        <h2>عن المتجر</h2>
        <details>
          <summary>طرق الدفع</summary>
          <p>
            نقدم طرق دفع متنوعة، تشمل البطاقات الائتمانية والدفع عند الاستلام.
          </p>
        </details>
        <details>
          <summary>الشحن والتسليم</summary>
          <p>الشحن مجاني لجميع المناطق، والتسليم يتم خلال 2-5 أيام عمل.</p>
        </details>
      </section>
      <div className="show" id="miniuDiv">
        {/* <!-- TAREKSCODE --> */}
        <h3 id="design">Designed And Developed By -</h3>
        <h3 style={{ fontSize: "15px" }} id="design">
          TareksCode
        </h3>
        <div className="LinksForTarekDiv">
          <a target="_blank" href="https://wa.me/972525272910">
            <img
              style={{ width: "50%" }}
              className="TarekLinksImages"
              src={whatsapp}
              alt=""
            />
          </a>
          <a target="_blank" href="https://www.instagram.com/tarekscode/">
            <img className="TarekLinksImages" src={instagram} alt="" />
          </a>
          <a target="_blank" href="tel:0525272910">
            <img
              className="TarekLinksImages"
              style={{ width: "50%" }}
              src={tel}
              alt=""
            />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
