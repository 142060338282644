import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./HomePage.css";
import { useState, useEffect, useContext } from "react";
import image1 from "./images/1.jpg";
import image2 from "./images/2.jpg";
import image3 from "./images/3.jpg";
import image4 from "./images/4.jpg";
import userContext from "../../userContext";
import { useNavigate } from "react-router-dom"; // لتوجيه المستخدم
import image5 from "./images/5.jpg";
import image8 from "./images/8.jpeg";
import image9 from "./images/9.JPG";
import image10 from "./images/10.JPG";
import image11 from "./images/11.JPG";
import image12 from "./images/12.jpeg";
import discount from "./images/discount70.jpeg";

import zainshop from "./zainshop.PNG";
import CountdownTimer from "../../Components/Timer/Timer";

export default function HomePage() {
  const { totalPrice, setTotalPrice, quantity, setQuantity } =
    useContext(userContext); // استخدام تحديث البيانات
  const images = [image12, image3, image5, image9, image10, image11, image1];
  const [selectedImage, setSelectedImage] = useState(images[0]); // تعيين الصورة الأولى كصورة افتراضية
  const [isLoading, setIsLoading] = useState(false); // حالة انتظار

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    country: "",
    quantity: 1, // إضافة عدد القطع هنا
  });
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleQuantityChange = (type) => {
    setFormData((prev) => ({
      ...prev,
      quantity:
        type === "increment"
          ? prev.quantity + 1
          : prev.quantity > 1
          ? prev.quantity - 1
          : prev.quantity,
    }));
  };

  const [showPopup, setShowPopup] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(false);
    }, 5000); // إخفاء البوب أب بعد 5 ثوانٍ
    return () => clearTimeout(timer);
  }, []);

  // حساب السعر النهائي بناءً على عدد القطع
  const calculateTotal = () => {
    const firstPiecePrice = 249.99;
    const additionalPiecePrice = 199.99;

    // السعر الإجمالي
    const total =
      firstPiecePrice + (formData.quantity - 1) * additionalPiecePrice;

    // السعر الأصلي بدون تخفيض
    const originalPrice = formData.quantity * 950;

    // مقدار التوفير
    const savings = originalPrice - total;
    setQuantity(formData.quantity);
    setTotalPrice(total);

    return { total: total.toFixed(2), savings: savings.toFixed(2) };
  };

  const navigate = useNavigate(); // التوجيه
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // تشغيل الـ loading

    try {
      const response = await fetch("/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formData,
          totalPrice,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to send email");
      }

      setTimeout(() => {
        navigate("/confirmation", { state: { formData, totalPrice } });
      }, 2000); // محاكاة انتظار
    } catch (error) {
      console.error("Error sending order email:", error);
      alert("حدث خطأ أثناء إرسال الطلب. حاول مرة أخرى.");
      setIsLoading(false);
    }
  };

  const myFeturesList = [
    "عند الانتهاء من نفخ العجل، الكرة او عجل السباحة ويصل الى الضغط المطلوب يتوقف بشكل تلقائي وذلك بفضل المستشعرات والحساسات الموجودة بداخله",
    "يقوم الجهاز باعطائك كمية الضغط الموجودة داخل العجل قبل البدء بالنفخ",
    "تمكنك الشاشة الالكترونية والازرار الموجودة على الجهاز من تحديد نوع المنتج اللذي تريد نفخه عجل سيارة كان ام كرة ام حتى عجل السباحة",
  ];
  const feturesList = () => {
    return myFeturesList.map((feature) => {
      return <li key={feature}>{feature}</li>;
    });
  };

  return (
    <div className="HomePage">
      {isLoading && (
        <div className="loading-overlay">
          <img src={zainshop} alt="Zainshop Logo" className="loading-logo" />
        </div>
      )}
      <div className="image-gallery">
        <h1>
          منفاخ زين الالكتروني يوفر عليك الوقت ويجعلك تشعر بالأمان
          <br />
        </h1>
        <div className="image-gallery-2">
          {/* <img src={image8} alt="image2" /> */}
          <img src={discount} alt="discount" />
        </div>

        {/* <div className="main-image-container">
          <img src={selectedImage} alt="Main" className="main-image" />
        </div>
        <div className="thumbnail-container">
          {images.map((img, index) => (
            <img
              key={index}
              src={img}
              alt={`Thumbnail ${index + 1}`}
              className={`thumbnail ${
                selectedImage === img ? "active-thumbnail" : ""
              }`}
              onClick={() => setSelectedImage(img)} // تحديث الصورة الكبيرة
            />
          ))}
        </div> */}
      </div>
      <div id="الفيديو_التعليمي" className="body-container">
        <p>
          استمتع بأفضل تجربة استثنائية لنفخ عجل السياره بأسهل وأسرع طريقة ممكنة
        </p>
        <div className="video-container">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube-nocookie.com/embed/Ds7i3YSBYYQ"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{ maxWidth: "100%", borderRadius: "10px" }}
          ></iframe>
        </div>
        <p>
          اذا كنت تبحث عن منفاخ الكتروني يجمع بين التكنلوجيا والاداء العالي في
          نفخ عجل السياره او كرة القدم او حتى عجل السباحة فمنفاخ زين هو الخيار
          الافضل والامثل لك
        </p>

        <p>
          تم تصميم هذا المنفاخ الإلكتروني ليوفر عليك الوقت وليوفر لك تجربة نفخ
          استثنائية للعجل ولا مثيل لها بفضل وجود بطاريتين بحجم <br />
          <span
            style={{
              color: "rgb(77, 46, 101)",
              fontSize: "25px",
              fontWeight: "bolder",
            }}
            id="معلومات_عن_المنفاخ"
          >
            4000mah
          </span>{" "}
          <br />
          وبفضل وجود شاشة الكترونيه وضوء قوي يساعدك على الرؤية بشكل واضح في
          الليل ولكي تستمتع بنفخ العجل باسرع وأسهل طريقه ممكنة من دون الحاجة الى
          اسلاك وبمجهود اقل
        </p>

        <ul className="fetures-list">{feturesList()}</ul>
        <div id="قدرة_المنفاخ" className="image-gallery-2">
          <img src={image2} alt="image2" />
        </div>
        <ul className="fetures-list">
          <li>
            صنع المنفاخ بجوده عالية جدا، ويمكنه ضخ 18(L/min) لتر من الهواء في
            الدقيقة ليمكنك من النفخ باسرع وقت ممكن فيوفر عليك الوقت في نفخ عجل
            السيارة والدراجة الهوائية او حتى الكرة
          </li>
        </ul>
        <div className="image-gallery-2">
          <img src={image3} alt="image3" />
        </div>
        <p id="تجربة_حقيقية">
          استمتع بمزايا منفاخنا المتكامل الذي يرافقك في كل احتياجاتك! سواء لنفخ
          كرة القدم، عجلات السيارة، أو حتى عجلات السباحة. بالإضافة إلى ذلك، يأتي
          مع المنفاخ شاحن عملي يتيح لك شحنه بسهولة من السيارة أو المنزل، لتبقى
          دائمًا مستعدًا في أي وقت وأي مكان.
        </p>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div className="video-container">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/j9gG78rT1_o"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{ maxWidth: "100%", borderRadius: "10px" }}
            ></iframe>
          </div>

          <h2
            style={{
              fontWeight: "900",
              fontSize: "20px",
            }}
          >
            ابتكار يجمع الراحة والكفاءة! اختر منفاخ زين واستمتع بسهولة النفخ مع
            توفير الوقت والجهد.
          </h2>
          {/* <h3
            id="Order"
            style={{
              width: "80%",
              animation: "bubble 1.5s infinite",
            }}
          >
            بدل{" "}
            <span
              style={{
                textDecoration: "line-through",
              }}
            >
              {" "}
              370₪{" "}
            </span>
            فقط 249.99 شيكل !
          </h3> */}
          <div
            className="image-gallery"
            style={{
              width: "100%",
            }}
          >
            <div className="main-image-container">
              <img src={selectedImage} alt="Main" className="main-image" />
            </div>
            <div className="thumbnail-container">
              {images.map((img, index) => (
                <img
                  key={index}
                  src={img}
                  alt={`Thumbnail ${index + 1}`}
                  className={`thumbnail ${
                    selectedImage === img ? "active-thumbnail" : ""
                  }`}
                  onClick={() => setSelectedImage(img)} // تحديث الصورة الكبيرة
                />
              ))}
            </div>
          </div>
          <h3
            id="Order"
            style={{
              width: "80%",
              backgroundColor: "transparent",
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "space-around",
            }}
          >
            <span style={{ fontSize: "22px" }}>249.99₪</span>
            <span
              style={{
                textDecoration: "line-through",
                backgroundColor: "gold",
              }}
            >
              {/* 370₪ */}
              830₪
            </span>
          </h3>
          <CountdownTimer />
          <h3
            style={{
              width: "80%",

              animation: "bubble 2s infinite",
            }}
          >
            والمنفاخ الثاني برضو مع الهدية فقط 199.99 شيكل
            <br />
          </h3>
          <h3
            style={{
              backgroundColor: "red",
              color: "white",
              width: "80%",

              animation: "bubble 1.5s infinite",
            }}
          >
            والتوصيل مجاني لجميع المناطق
          </h3>
        </div>
        {/* <div className="under-image-price">
          <h3>500₪</h3>
          <h2>249.99₪</h2>
        </div> */}
        <form className="order-form" onSubmit={handleSubmit}>
          <h2 style={{ fontWeight: "bolder" }}>للطلب بطريقة سهلة :</h2>
          <div className="inputs">
            <input
              type="text"
              name="name"
              placeholder="مثلّا : احمد علي"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="tel"
              name="phone"
              placeholder="مثلّا : 052XXXXXXX"
              value={formData.phone}
              onChange={(e) => {
                const value = e.target.value;
                // السماح فقط بالأرقام والتحقق من الطول
                if (/^\d{0,10}$/.test(value)) {
                  setFormData({ ...formData, phone: value });
                }
              }}
              pattern="\d{10}" // السماح فقط بعشرة أرقام
              title="يجب أن يتكون رقم الهاتف من 10 أرقام فقط"
              required
            />

            <input
              type="text"
              name="country"
              placeholder="مثلّا : القدس / شارع صلاح الدين"
              value={formData.country}
              onChange={handleChange}
              required
            />
            <h2
              style={{
                border: "2px solid black",
                color: "black",
                fontSize: "1rem",
                width: "90%",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                textAlign: "start",
                padding: "10px",
                fontWeight: "900",
                margin: "0px",
                transition: " border-color 0.3s ease",
                borderRadius: "5px",
                backgroundColor: "white",
              }}
            >
              الدفع عند الاستلام
            </h2>
            {/* السعر النهائي وتوفير الزبون */}
            <div style={{ textAlign: "center" }}>
              <h4>السعر النهائي: {calculateTotal().total} شيكل</h4>
              <h4
                style={{
                  backgroundColor: "gold",
                  fontSize: "22px",
                  animation: "bubble 1.5s infinite",
                }}
              >
                لقد وفرت: {calculateTotal().savings} شيكل!
              </h4>
              <h4 style={{ color: "red" }}>الحملة لفترة محدودة !</h4>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                justifyContent: "space-around",
                alignItems: "center",
                width: "100%",
              }}
            >
              <button
                type="submit"
                id="click-here-to-order-button"
                className="click-here-to-order-button"
                style={{ fontSize: "15px" }}
              >
                اضغط هنا للطلب
              </button>
              {/* التحكم بعدد القطع */}
              <div className="quantity-container">
                <button
                  type="button"
                  className="quantity-button"
                  onClick={() => handleQuantityChange("decrement")}
                >
                  -
                </button>
                <input
                  type="number"
                  className="quantity-input"
                  name="quantity"
                  value={formData.quantity}
                  readOnly
                />
                <button
                  type="button"
                  className="quantity-button"
                  onClick={() => handleQuantityChange("increment")}
                >
                  +
                </button>
              </div>
            </div>
            <h2>او</h2>
            <a
              style={{
                fontSize: "15px",
                backgroundColor: "#25d366",
                textDecoration: "none",
              }}
              className="click-here-to-order-button"
              href="https://wa.me/972528919198?text=أنا%20معني%20بالدفع%20لشراء%20منفاخ%20زين%20عن%20طريق%20بطاقة%20الائتمان" // استبدل الرقم برقم صاحب المشروع
              target="_blank"
              rel="noopener noreferrer"
            >
              اضغط هنا للدفع ببطاقة الائتمان عبر واتس اب
            </a>
          </div>
        </form>
      </div>
      {/* زر الواتساب */}
      <div className="whatsapp-button">
        <a
          href="https://wa.me/972528919198" // استبدل الرقم برقم صاحب المشروع
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-whatsapp"></i> {/* أيقونة واتساب */}
        </a>
        {showPopup && (
          <div className="whatsapp-popup">
            نحن متاحون من أجلك عبر واتس اب أيضًا
          </div>
        )}
      </div>
      {/* الزر الثابت
      <a className="fixed-button" href="#Order">
        اضغط هنا للطلب
      </a> */}
    </div>
  );
}
